<template>
  <validation-observer
    ref="checkExaminationModalFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="checkExaminationModal"
      centered
      title="Phúc khảo điểm thi"
      size="lg"
      body-class="position-static"
      @hide="reason = ''"
    >
      <b-form>
        <b-form-group label-for="name-input">
          <template v-slot:label>
            Lý do <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Lý do"
            rules="required"
          >
            <b-form-input
              id="name-input"
              v-model="reason"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Gửi yêu cầu
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('checkExaminationModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormInput, BModal, BOverlay, BFormGroup, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'StudentCheckExamination',
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BOverlay,
    BFormGroup,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    studentExaminationRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      reason: '',
      required,
    }
  },
  methods: {
    ...mapActions({
      createStudentCheckExamination: 'studentCheckExamination/createStudentCheckExamination',
    }),
    onSave() {
      this.$swal({
        title: 'Bạn muốn gửi yêu cầu phúc khảo?',
        text: 'Vui lòng kiểm tra lại thông tin trước khi gửi yêu cầu',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const valid = this.$refs
            .checkExaminationModalFormRef
            .validate()
          if (valid) {
            this.isLoading = true
            try {
              const response = await this.createStudentCheckExamination({
                studentExaminationRoomId: this.studentExaminationRoomId,
                description: this.reason,
                status: Flag.ACTIVE,
              })
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  this.$bvModal.hide('checkExaminationModal')
                  this.$emit('succeed')
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        }
      })
    },
  },
}
</script>
