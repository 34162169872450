<template>
  <b-modal
    id="scoreModal"
    centered
    title="Danh sách điểm"
    size="lg"
    body-class="position-static"
    hide-footer
    @shown="onShown"
  >
    <div class="d-block text-center">
      <h3>Danh sách điểm môn: <span class="text-primary">{{ studentExamination.subjectName }}</span></h3>
    </div>
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'status'">
              <b-badge
                v-if="props.row.scoreType === 1 && !!props.row.status"
                pill
                :variant="props.row.status === 1 ? 'light-primary' : 'light-success'"
              >
                {{ getStatusName(props.row.status) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-if="isShowCheckExamination(props.row)"
                v-b-modal.checkExaminationModal
                variant="flat-primary"
                class="btn-icon"
                size="sm"
                title="Phúc khảo điểm thi"
              >
                <feather-icon icon="SendIcon" />
              </b-button>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
      no-wrap
    />

    <StudentCheckExamination
      :student-examination-room-id="studentExamination.studentExaminationRoomId"
      @succeed="onShown"
    />
  </b-modal>
</template>

<script>
import {
  BButton, BCol, BFormSelect, BModal, BOverlay, BPagination, BRow, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import StudentCheckExamination from '@/views/study/learning-outcome/StudentCheckExamination.vue'

export default {
  name: 'Score',
  components: {
    StudentCheckExamination,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BPagination,
    BModal,
    BFormSelect,
    VueGoodTable,
    BBadge,
  },
  props: {
    studentExamination: {
      type: Object,
      default: () => ({
        studentExaminationRoomId: null,
        score: null,
        studentCreditClassId: null,
        subjectName: '',
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên điểm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên điểm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giá trị',
          field: 'value',
          filterOptions: {
            enabled: false,
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          sortFn: this.sortFn,
        },
        {
          label: 'Trạng thái phúc khảo',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'action',
          label: '',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'study/scores',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getAllScoresByStudentCreditClass: 'study/getAllScoresByStudentCreditClass',
    }),
    async onShown() {
      this.isLoading = true
      try {
        await this.getAllScoresByStudentCreditClass({ studentCreditClassId: this.studentExamination.studentCreditClassId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    sortFn(x, y) {
      if (x < y) return -1
      return x > y ? 1 : 0
    },
    isShowCheckExamination(data) {
      return !!this.studentExamination.studentExaminationRoomId && !data.status && data.scoreType === 1
    },
    getStatusName(status) {
      if (!status) return ''
      return status === 1 ? 'Đang xử lý' : 'Đã xử lý'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
